import React, { useContext } from "react"

/** Components */
import { SideRailContext } from "../../SideRail/SideRailContext"
/** Types */
import { tProject } from "../../../cached-data/types"
import { ResourceObject } from "../../../dashboard-data/types"

type Props = {
    data: ResourceObject<tProject>
}

const ModifyButtonCellRenderer = (flow: "PROJECT_MODIFY" | "GROUP_MODIFY"): React.FC<Props> => {
    return ({ data }) => {
        const { enableSideRail } = useContext(SideRailContext)

        const itemId = data.id

        if (flow === "GROUP_MODIFY" && !itemId) {
            // Unlike Project Modify, Group Modify requires an existing group
            // So no button until a group ID is available.
            return <></>
        }

        const startModifyFlow = () => {
            if (flow === "PROJECT_MODIFY") {
                if (itemId) enableSideRail({ flow, projectData: data })
            } else if (flow === "GROUP_MODIFY") {
                // Typescript doesn't know we have an itemID if we got here, so checking again
                if (itemId) enableSideRail({ flow, groupId: itemId })
            }
        }

        return (
            <button onClick={startModifyFlow} type="button" className="action-button">
                <div>Modify</div>
            </button>
        )
    }
}

export default ModifyButtonCellRenderer
