import {
    booleanColDefCreator,
    companyGroupColDefCreator,
    employeeColDefCreator,
    checkboxColDefCreator,
    integerColDefCreator,
    statusColDefCreator,
    stringColDefCreator,
    modifyButtonColDefCreator,
} from "./standard-col-def-creators"
import { employeeRoleValueSetter } from "../../../common/ag-grid-value-setters"
import {
    titleCaseFormatter,
    phoneNumberFormatter,
    isActiveValueFormatter,
} from "../../../common/ag-grid-value-formatters"
import { EMPLOYEE_ROLE_OPTIONS } from "../../../common/constants"
import { singleGroupFilterDef } from "../../../filters/filter-defs"
import { getGroupsFieldFormsTabSettings } from "./field-forms-dashboard"
import { getGroupsShiftExtrasTabSettings } from "./shift-extras-dashboard"
import { arrayOfReferenceablesValueGetter } from "../../../common/ag-grid-value-getters"
import { getGroupsTkModifierTabSettings } from "./timekeeping-modifiers-dashboard"
import { getFlagEnabled } from "../../../getFlagValue"
import { GroupSettingsHeader } from "../custom-headers/group-settings-header"

const filters = [singleGroupFilterDef]

const tabGridSettings = {
    rowHeight: 40,
    suppressRowClickSelection: true, // allow our click events to happen
}

const projectsSettings = {
    tableName: "Projects",
    resources: ["projects"],
    filters: filters,
    colDefs: [
        stringColDefCreator({
            headerName: "Job #",
            field: "/job_number",
            sort: "desc",
        }),
        stringColDefCreator({
            headerName: "Name",
            field: "/name",
        }),
        stringColDefCreator({
            headerName: "Location",
            field: "/address",
            width: 320,
        }),
        {
            ...companyGroupColDefCreator({
                editable: true,
            }),
            cellUpdateModal: {
                title: "Move Project",
                description:
                    "All project Team Members will move with the project. \
                     A different set of Group Members may now be able to access the project.",
                confirmButtonText: "Move",
            },
        },
        integerColDefCreator({
            headerName: "Workers",
            field: "/employees_count",
        }),
        integerColDefCreator({
            headerName: "Cost Codes",
            field: "/cost_codes_count",
        }),
        statusColDefCreator(),
    ],
    gridSettings: { ...tabGridSettings },
    otherSettings: {
        pageTitle: "Projects",
        subcontent: "Groups are a way to assign projects to different regions and sub-sections.",
        rowLevelErrorDisplay: true,
        buttons: {
            cell: [],
            row: [],
            table: [
                {
                    label: "Create Project",
                    icon: "add",
                    action: "addProject",
                },
                {
                    label: "Export ▾",
                    icon: "export",
                    action: "export",
                },
            ],
        },
    },
}

const getMembersSettings = featureFlags => {
    const showLaborType = featureFlags.cost_code_controls
    return {
        tableName: "Members",
        resources: ["groupMembers"],
        filters: filters,
        colDefs: [
            checkboxColDefCreator(),
            {
                field: "/group",
                hide: true,
            },
            employeeColDefCreator({
                editable: true,
                excludeFromExport: true,
            }),
            stringColDefCreator({
                headerName: "Employee ID*",
                field: "/company_supplied_id",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            stringColDefCreator({
                headerName: "First Name*",
                field: "/first_name",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            stringColDefCreator({
                headerName: "Last Name*",
                field: "/last_name",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            {
                ...stringColDefCreator({
                    headerName: "Role*",
                    field: "/user_role",
                    width: 130,
                    enum: EMPLOYEE_ROLE_OPTIONS,
                    referenceableMap: {
                        referenceableKey: "employees",
                        rowKey: "employee",
                    },
                }),
                valueFormatter: titleCaseFormatter,
                valueSetter: employeeRoleValueSetter,
            },
            {
                ...stringColDefCreator({
                    headerName: "Mobile Phone",
                    field: "/phone",
                    referenceableMap: {
                        referenceableKey: "employees",
                        rowKey: "employee",
                    },
                    headerTooltip: "Required for Foremen",
                }),
                valueFormatter: phoneNumberFormatter,
            },
            {
                ...stringColDefCreator({
                    headerName: "Email",
                    field: "/email",
                    referenceableMap: {
                        referenceableKey: "employees",
                        rowKey: "employee",
                    },
                    width: 230,
                    headerTooltip: "Required for roles other than Worker",
                }),
            },
            stringColDefCreator({
                headerName: "Classification",
                field: "/classification",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            stringColDefCreator({
                headerName: "Trade",
                field: "/trade",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            stringColDefCreator({
                headerName: "Labor Type",
                editable: false,
                field: "/crew_types",
                hide: !showLaborType,
                excludeFromExport: true,
                valueGetter: params => arrayOfReferenceablesValueGetter(params, "name", ", "),
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            {
                ...booleanColDefCreator({
                    headerName: "Status",
                    field: "/is_active",
                    referenceableMap: {
                        referenceableKey: "employees",
                        rowKey: "employee",
                    },
                    minWidth: 100,
                    editable: false,
                }),
                valueFormatter: isActiveValueFormatter,
            },
        ],
        gridSettings: { ...tabGridSettings, rowSelection: "multiple" },
        otherSettings: {
            pageTitle: "Members",
            subcontent: "Groups are a way to assign employees to projects in different regions and sub-sections.",
            rowLevelErrorDisplay: true,
            hiddenColumnDefaults: {
                group: "groupId",
            },
            buttons: {
                cell: [
                    {
                        label: "Edit",
                        icon: "edit",
                        action: "editFocusedCell",
                    },
                    {
                        label: "Copy",
                        icon: "copy",
                        action: "copyFocusedCell",
                    },
                    {
                        label: "Paste",
                        icon: "paste",
                        action: "pasteIntoFocusedCell",
                    },
                ],
                row: [
                    {
                        label: "Remove",
                        icon: "delete",
                        action: "deleteSelectedRows",
                    },
                ],
                table: [
                    {
                        label: "Add Row",
                        icon: "add",
                        action: "addNewRow",
                    },
                    {
                        label: "Upload",
                        icon: "upload",
                        action: "navigateTo",
                        args: { url: "/rhumbix/company-admin/groups/members/upload/" },
                    },
                    {
                        label: "Export ▾",
                        icon: "export",
                        action: "export",
                    },
                ],
            },
            enableSearchBar: true,
        },
    }
}

const getGroupSettings = () => ({
    tableName: "Subgroups",
    resources: ["companyGroups"],
    filters: filters,
    colDefs: [
        checkboxColDefCreator(),
        {
            field: "/parent_id",
            hide: true,
        },
        stringColDefCreator({
            headerName: "Name*",
            field: "/name",
            required: true,
            editable: true,
        }),
        stringColDefCreator({
            headerName: "Description",
            field: "/description",
            editable: true,
        }),
        integerColDefCreator({
            headerName: "Projects",
            field: "/project_ids/length",
        }),
        integerColDefCreator({
            headerName: "Members",
            field: "/employee_ids/length",
        }),
        integerColDefCreator({
            headerName: "Subgroups",
            field: "/children_ids/length",
        }),
        booleanColDefCreator({
            headerName: "Grant Employee Access",
            field: "/grants_employee_access",
            editable: true,
        }),
        ...(getFlagEnabled("WA-8439-group-right-rail")
            ? [
                  modifyButtonColDefCreator({
                      headerName: "Settings",
                      field: "/id",
                      modifyFlow: "GROUP_MODIFY",
                  }),
              ]
            : []),
    ],
    gridSettings: { ...tabGridSettings, rowSelection: "multiple" },
    otherSettings: {
        pageTitle: "Groups",
        subcontent: "Groups are a way to assign projects to different regions and sub-sections.",
        rowLevelErrorDisplay: true,
        hiddenColumnDefaults: {
            parent_id: "groupId",
        },
        buttons: {
            cell: [
                {
                    label: "Edit",
                    icon: "edit",
                    action: "editFocusedCell",
                },
                {
                    label: "Copy",
                    icon: "copy",
                    action: "copyFocusedCell",
                },
                {
                    label: "Paste",
                    icon: "paste",
                    action: "pasteIntoFocusedCell",
                },
            ],
            row: [
                {
                    label: "Delete",
                    icon: "delete",
                    action: "deleteSelectedRows",
                },
            ],
            table: [
                {
                    label: "Add Row",
                    icon: "add",
                    action: "addNewRow",
                },
                {
                    label: "Export ▾",
                    icon: "export",
                    action: "export",
                },
            ],
        },
    },
})

export const getCompanyGroupsSettings = (featureFlags, currentProject, currentUser) => {
    const fieldFormSettings = getGroupsFieldFormsTabSettings(featureFlags, currentProject, currentUser)
    const shiftExtraSettings = getGroupsShiftExtrasTabSettings(featureFlags, currentProject, currentUser)
    const tkModifierSettings = getGroupsTkModifierTabSettings(featureFlags, currentProject, currentUser)

    const { user_role } = currentUser

    const resources = ["companyGroups", "projects", "groupMembers"]
    const membersSettings = getMembersSettings(featureFlags)
    const tableList = [membersSettings, projectsSettings, getGroupSettings()]

    if (["ADMIN", "PAYROLL_ADMIN", "PM"].includes(user_role)) {
        if (featureFlags["custom_form"]) {
            resources.push("companyFormSchemasForDashboard")
            tableList.push(fieldFormSettings)
        }

        if (featureFlags["shift_extras"]) {
            resources.push("employeeSchemasForDashboard")
            tableList.push(shiftExtraSettings)
        }
    }

    if (getFlagEnabled("WA-7632-project-specific-modifiers")) {
        if (featureFlags["cost_code_level_modifiers"]) {
            resources.push("timekeepingModifiersForDashboard")
            tableList.push(tkModifierSettings)
        }
    }

    const settings = {
        tableName: "Groups",
        navId: "groups",
        filters: filters,
        resources,
        additionalQueryParams: {
            queryParamsByResource: {
                projects: {
                    [getFlagEnabled("WA-8281-fix-for-group-filter")
                        ? "exclude_subgroups"
                        : "exclude_subgroup_projects"]: true,
                    annotations: true,
                },
                companyFormSchemasForDashboard: {
                    ...fieldFormSettings.additionalQueryParams,
                    exclude_subgroups: true,
                },
                employeeSchemasForDashboard: {
                    ...shiftExtraSettings.additionalQueryParams,
                    exclude_subgroups: true,
                },
                timekeepingModifiersForDashboard: {
                    ...tkModifierSettings.additionalQueryParams,
                },
            },
        },
        relatedNames: {
            groupMembers: {
                employees: ["/employee"],
            },
        },
        tabbedTableWrapper: {
            tableList,
        },
        otherSettings: {
            pageTitle: "Groups",
            subcontent: "Groups are a way to assign projects to different regions and sub-sections.",
            customHeaderComponent: GroupSettingsHeader,
        },
    }

    return settings
}
