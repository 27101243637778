/** Components */
import AttachmentsRenderer from "../cell-renderers/attachments-cell-renderer"
import ModifierCellRenderer from "../cell-renderers/ModifierCellRenderer"
import CheckMarkCellRenderer from "../cell-renderers/check-mark-cell-renderer"
import DefaultCellRenderer from "../cell-renderers/default-cell-renderer"
import ModifyButtonCellRenderer from "../cell-renderers/ModifyButtonCellRenderer"
import MultiChoiceCellRenderer from "../cell-renderers/multi-choice-cell-renderer"
import MultiLineCellRenderer from "../cell-renderers/MultiLineCellRenderer"
import SlideToggleCellRenderer from "../cell-renderers/SlideToggleCellRenderer"
import TimeCardTitleCellRenderer from "../cell-renderers/time-card-title-cell-renderer"
import TkTimeCardTitleCellRenderer from "../cell-renderers/tk-time-card-title-cell-renderer"
import TokenRowButtonCellRenderer from "../cell-renderers/TokenRowButtonCellRenderer"
import ClickableTextCellRenderer from "../cell-renderers/clickable-text-cell-renderer"
import WorkflowButtonCellRenderer from "../cell-renderers/WorkflowButtonCellRenderer"

import MultiChoiceCellEditor from "../cell-editors/multi-choice-cell-editor"
import ModifierSelectorCellEditor from "../cell-editors/modifier-selector-cell-editor"
import ReferenceableSelectorCellEditor from "../cell-editors/referenceable-selector-cell-editor"
import TimeCellEditor from "../cell-editors/time-cell-editor"
import ColorCellEditor from "../cell-editors/color-cell-editor"

/** Value Getters */
import {
    durationSecondsValueGetter,
    hoursFromMinutesValueGetter,
    referenceableValueGetter,
    rowCountValueGetter,
    USDSumValueGetter,
    USDValueGetter,
    apiIntegrationTokenValueGetter,
    jsonPointerValueGetter,
} from "../../../common/ag-grid-value-getters"
import { getProductionWeekHeader, ptHoursQuantitiesValueGetter } from "../ProductionTracking/value-getters"
import {
    employeeMainGroupAvailableValueSetter,
    subcontractorValueSetter,
} from "../../../common/ag-grid-value-setters"
/** Value Formatters */
import {
    booleanValueFormatter,
    timeValueFormatter,
    dateValueFormatter,
    titleCaseFormatter,
    USDValueFormatter,
    multiChoiceValueFormatter,
    employeeMainGroupAvailableValueFormatter,
    userRoleChoiceValueFormatter,
    filterFormatter,
    customStatusValueFormatter,
} from "../../../common/ag-grid-value-formatters"
import { referenceablesToValueFormatters } from "../../../common/referenceable-value-formatters"
/** Comparators */
import { defaultComparator, getFormattedValueComparatorForField } from "../../../common/ag-grid-comparators"
import { compareDateStrings } from "../../../common/ag-grid-ts-comparators"
import {
    isInteger,
    isIntegerOrNull,
    isNotEmpty,
    isNumber,
    isNumberOrNull,
    validateUniqueEntry,
} from "../../../common/validators"
import {
    hoursToMinutesValueParser,
    numberOrNullValueParser,
    numberValueParser,
    USDValueParser,
} from "../../../common/ag-grid-value-parsers"
import { dateKeyCreator, findShiftRowData } from "../../../common/ag-grid-ts-utils"
import {
    idKeyCreator,
    isGroupRow,
    referenceableKeyToValue,
    suppressKeyboardHandler,
} from "../../../common/ag-grid-utils"
import {
    EMPLOYEE_MAIN_GROUP_AVAILABLE_SELECTOR_OPTIONS,
    EMPLOYEE_PROJECT_SPECIFIC_ASSIGNMENTS,
    STORED_DATE_ONLY_FORMAT,
    weeklyTkDummyProject,
} from "../../../common/constants"
import * as helpers from "@rhumbix/rmbx_templates/dist/helpers/index.js"
const FieldFormHelpers: Record<string, (...args: any) => unknown> = helpers

/** Types */
import { iColDefCreator, iRmbxColDef, RmbxColDefParams, tStandardColDefCreators } from "./types"
import { tProductionWeekValue } from "../ProductionTracking/types"
import { getFlagEnabled } from "../../../getFlagValue"
import { isEmpty } from "lodash"
import BooleanCheckboxCellRenderer from "../cell-renderers/BooleanCheckboxCellRenderer"
import LimitedLengthStringCellEditor from "../cell-editors/limited-length-string-cell-editor"
import SignatureActionsCellRenderer from "../cell-renderers/signatureActionsCellRenderer"
import { format, parse } from "date-fns"
import { RowNode } from "ag-grid-community"
import { tSelectorOption } from "../../../SelectorV3/types"
import ColorPickerCellRenderer from "../cell-renderers/color-picker-cell-renderer"
import { iTimekeepingStatus } from "../../../cached-data/types"
import CicoEmployeeOption from "../../../Selector/CicoEmployeeOption"
import SumFieldsCellRenderer from "../cell-renderers/SumFieldsCellRenderer"
import FFTemplateCellRenderer from "../cell-renderers/FFTemplateCellRenderer"
import ViewCicoHistoryButtonCellRenderer from "../cell-renderers/ViewCicoHistoryButtonCellRenderer"

const standardColDefCreator: iColDefCreator = params => {
    const { width } = params
    const pinned = params.pinned ? { pinned: params.pinned } : {}

    return {
        headerName: params.headerName,
        headerTooltip: params.headerTooltip,
        field: params.field,
        minWidth: width || 130,
        referenceableMap: params.referenceableMap,
        hide: params.hide !== undefined ? params.hide : false,
        excludeFromExport: !!params.excludeFromExport,
        // if shouldPreventActionFocus is set to true, the cell will not show the action bar when on focus
        shouldPreventActionFocus: !!params.shouldPreventActionFocus,
        groupColumn: !!params.groupColumn,
        sort: params.sort,
        cellRenderer: params.cellRenderer || DefaultCellRenderer,
        ...(params.field === undefined ? { editable: false } : {}),
        ...(params.editable !== undefined ? { editable: params.editable } : {}),
        suppressSizeToFit: params.suppressSizeToFit,
        width: params.fixedWidth ? width : undefined,
        // Focus on the column when adding a new row.
        focusedCell: params.focusedCell,
        // If setDefault is true, cell value is set to default value even if the cell is not editable.
        setDefault: params.setDefault,
        uneditableMessage: params.uneditableMessage,
        ...pinned,
        ...(params.cellClassRules ? { cellClassRules: params.cellClassRules } : {}),
        ...(params.parentContainerId ? { parentContainerId: params.parentContainerId } : {}),
    }
}

export const stringColDefCreator: iColDefCreator = (params = {}) => {
    const {
        cellRenderer,
        comparator,
        icon,
        iconStyles,
        valueGetter,
        valueFormatter,
        valueSetter,
        getQuickFilterText,
    } = params
    let validators = params.cellValidators
    if (!validators) validators = params.required ? [isNotEmpty] : undefined
    return {
        ...standardColDefCreator(params),
        minWidth: params.width || 160,
        cellEditor: params.enum ? "agRichSelect" : "agTextCellEditor",
        cellEditorParams: {
            default: params.default || undefined,
            validators,
            values: params.enum || undefined,
        },
        cellEditorPopup: params.cellEditorPopup || params.enum,
        icon,
        iconStyles,
        ...(cellRenderer && { cellRenderer }),
        ...(comparator && { comparator }),
        ...(valueFormatter && { valueFormatter }),
        ...(valueGetter && { valueGetter }),
        ...(valueSetter && { valueSetter }),
        ...(getQuickFilterText && { getQuickFilterText }),
        rowDrag: params.rowDrag ?? undefined,
        columnType: "string",
    }
}

export const lengthLimitedColDefCreator: iColDefCreator = (params = {}) => {
    const { width, maxLength } = params
    const cellRenderer: Record<string, any> = {}
    if (params.cellRenderer) {
        cellRenderer.cellRenderer = params.cellRenderer
    }
    let validators = params.cellValidators
    if (!validators) validators = params.required ? [isNotEmpty] : undefined
    return {
        ...standardColDefCreator(params),
        validators,
        minWidth: width || 160,
        cellEditor: LimitedLengthStringCellEditor,
        cellEditorParams: {
            maxLength: maxLength,
            width: width || 160,
        },
        ...cellRenderer,
    }
}

export const numberColDefCreator: iColDefCreator = (params = {}) => {
    const { valueGetter, valueFormatter } = params
    let validators = params.cellValidators
    if (!validators) validators = params.required ? [isNumber] : [isNumberOrNull]

    const config = {
        ...standardColDefCreator(params),
        valueParser: params.required ? numberValueParser : numberOrNullValueParser,
        cellEditorParams: {
            ...(params.default !== undefined ? { default: params.default } : params.required ? { default: 0 } : {}),
            validators,
        },
        width: params.width || 130,
        suppressSizeToFit: true,
        cellStyle: params.cellStyle,
        headerClass: params.headerClass,
        columnType: "number",
        ...(valueFormatter && { valueFormatter }),
    }
    if (valueGetter) config.valueGetter = valueGetter
    return config
}

export const integerColDefCreator: iColDefCreator = (params = {}) => {
    const numberColDef = numberColDefCreator(params)
    return {
        ...numberColDef,
        cellEditorParams: {
            ...numberColDef.cellEditorParams,
            validators: params.required ? [isInteger] : [isIntegerOrNull],
        },
    }
}

export const booleanColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...standardColDefCreator(params),
        valueFormatter: booleanValueFormatter,
        cellEditor: "agRichSelect",
        cellEditorParams: {
            default: params.default ? params.default : false,
            values: [true, false],
        },
        columnType: "boolean",
        cellEditorPopup: true,
    }
}

export const multiChoiceColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...standardColDefCreator(params),
        valueSetter: () => true,
        valueGetter: p => {
            const attr = params.attribute ? params.attribute : "default"
            return p.data[attr]
        },
        cellRenderer: MultiChoiceCellRenderer,
        cellEditor: MultiChoiceCellEditor,
        cellEditorParams: {
            default: params.default ? params.default : false,
            disableOptions: [],
        },
    }
}

export const userRoleChoiceColDefCreator: iColDefCreator = (params = {}) => {
    // This is set up for use with the Design System Selector Component
    return {
        ...standardColDefCreator(params),
        multiselect: true,
        valueFormatter: userRoleChoiceValueFormatter,
        cellEditor: MultiChoiceCellEditor,
        cellEditorPopup: true,
        cellEditorParams: {
            choices: ["ADMIN", "OFFICE_STAFF", "PAYROLL_ADMIN", "PM", "FOREMAN"].map(role => {
                const disabled = ["ADMIN", "OFFICE_STAFF"].includes(role)
                const tooltip = disabled ? "This setting can only be changed by Rhumbix" : null
                return { label: role.replace(/_/g, " "), value: role, isDisabled: disabled, tooltip: tooltip }
            }),
            fixedOptions: ["ADMIN", "OFFICE_STAFF"], // if these are selected, they cannot be deselected, aka fixed
        },
        columnType: "userRoleChoice",
    }
}

export const referenceableColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...standardColDefCreator(params),
        minWidth: params.width || 200,
        keyCreator: idKeyCreator,
        keyToValue: referenceableKeyToValue,
        valueGetter: referenceableValueGetter,
        comparator: getFormattedValueComparatorForField(params.field),
        cellEditor: ReferenceableSelectorCellEditor,
        cellEditorPopup: true,
        cellEditorParams: {
            skipProjectFilter: params?.skipProjectFilter,
            idsToExclude: params.idsToExclude && params.idsToExclude.length ? params.idsToExclude : null,
            default: params.default ? params.default : null,
            disableInactive: params.disableInactive ? params.disableInactive : null,
            isDesignSystem: params.isDesignSystem ?? false,
            validators: [
                ...(params.required ? [isNotEmpty] : []),
                ...(params.unique ? [validateUniqueEntry] : []),
                ...(params.cellValidators ?? []),
            ],
            canFieldAddItems: params.canFieldAddItems,
        },
        suppressKeyboardEvent: suppressKeyboardHandler,
        multiselect: params.multiselect,
        useSelectV3: true,
    }
}

export const projectColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["projects"] || {}

    return {
        ...referenceableColDefCreator({
            headerName: "Project",
            field: "/project",
            required: true,
            ...params,
        }),
        minWidth: 250,
        valueFormatter: valueFormatters.valueFormatter,
        cellRendererParams: {
            default: params.default ? params.default : null,
            moreDetail: params.moreDetail,
            ...valueFormatters,
            titleFormatter: params.titleFormatter ?? valueFormatters.titleFormatter,
        },
        resourceName: "projects",
        filterQueryParam: "project_id",
        fakeKey: "-1",
        fakeValue: weeklyTkDummyProject,
    }
}

export const companyGroupColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["companyGroups"] || {}

    return {
        ...referenceableColDefCreator({
            headerName: "Group",
            field: "/group",
            required: true,
            ...params,
        }),
        valueFormatter: valueFormatters.valueFormatter,
        cellRendererParams: {
            moreDetail: params.moreDetail,
            ...valueFormatters,
        },
        resourceName: "companyGroups",
        filterQueryParam: "group",
    }
}

// When an employee is assigned to a group, their options depend on the Groups company feature toggle. A customer
// who is paying for Groups can assign an employee to any of the available groups or subgroups; but if they
// don't have that feature, they get a truncated version that lets them assign an employee either to Main,
// or to no groups at all. The purpose of that option isn't so much to let them use Groups but to give them an
// easy way to give a resource access to all of the projects at once (or alternately, take that access away)
export const employeeGroupsColDefCreator: iColDefCreator = (params = {}) => {
    // GLO customers get the full dropdown with all of the options
    return params.groupsCompanyFeatureToggleActive
        ? {
              ...referenceableColDefCreator({
                  headerName: "Groups",
                  field: "/groups",
                  required: false,
                  multiselect: true,
                  ...params,
              }),
              comparator: (valueA, valueB) =>
                  defaultComparator(multiChoiceValueFormatter(valueA), multiChoiceValueFormatter(valueB)),
              valueFormatter: p => multiChoiceValueFormatter(p.value),
              resourceName: "companyGroups",
              filterQueryParam: "groups",
              useSelectV3: true,
              columnType: "group",
          }
        : // ... Non-GLO customers get the truncated version
          {
              ...stringColDefCreator({
                  headerName: "Groups",
                  field: "/groups",
                  required: false,
                  multiselect: false,
                  enum: EMPLOYEE_MAIN_GROUP_AVAILABLE_SELECTOR_OPTIONS,
                  default: EMPLOYEE_PROJECT_SPECIFIC_ASSIGNMENTS,
                  ...params,
              }),
              comparator: (valueA, valueB) =>
                  defaultComparator(multiChoiceValueFormatter(valueA), multiChoiceValueFormatter(valueB)),
              valueFormatter: employeeMainGroupAvailableValueFormatter,
              valueSetter: employeeMainGroupAvailableValueSetter,
              columnType: "string",
          }
}

export const employeeCohortsColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...referenceableColDefCreator({
            headerName: "Cohorts",
            field: "/cohort_permissions",
            required: false,
            multiselect: true,
            ...params,
        }),
        comparator: (valueA, valueB) =>
            defaultComparator(multiChoiceValueFormatter(valueA), multiChoiceValueFormatter(valueB)),
        valueFormatter: p => multiChoiceValueFormatter(p.value),
        resourceName: "cohorts",
        useSelectV3: true,
    }
}

export const subcontractorColDefCreator = (
    params = {},
    hasProjectShare: boolean,
    currentProject: Record<string, any>,
    companyRole: string
) => {
    return {
        ...referenceableColDefCreator({
            headerName: "Subcontractors",
            field: "/cost_items",
            required: false,
            minWidth: 250,
            multiselect: true,
            ...params,
        }),
        extraSearchFilters: {
            unique_subs: true,
            project_id: currentProject?.id,
        },
        cellEditorParams: {
            /**
             * if a gc is self-perform (has no project share) -
             * set the gc company as the default value for the subcontractor
             */
            default: !hasProjectShare ? [{ sub_company_id: currentProject?.company_id }] : null,
            valueKey: "sub_company_id",
        },
        cellRendererParams: {
            default: !hasProjectShare ? [{ sub_company_id: currentProject?.company_id }] : null,
            getOptionValue: (option: tSelectorOption) => {
                return option.data?.sub_company_id
            },
            ...(referenceablesToValueFormatters["projectShares"] || {}),
        },

        hide: !hasProjectShare || companyRole !== "GC",

        comparator: (valueA: string, valueB: string) =>
            defaultComparator(multiChoiceValueFormatter(valueA), multiChoiceValueFormatter(valueB)),
        valueFormatter: referenceablesToValueFormatters["projectShares"]?.valueFormatter,
        resourceName: "projectShares",
        useSelectV3: true,
        editable: !!currentProject,
        valueSetter: subcontractorValueSetter,
    }
}

export const employeeColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["employees"] || {}
    const customOptionParam = params.includeCico ? { CustomOption: CicoEmployeeOption } : {}
    const referenceableColDef = referenceableColDefCreator({
        headerName: "Employee",
        field: "/employee",
        required: true,
        ...params,
    })
    return {
        ...referenceableColDef,
        valueFormatter: valueFormatters.valueFormatter,
        cellRendererParams: {
            moreDetail: params.moreDetail,
            includeCico: params.includeCico,
            ...valueFormatters,
        },
        cellEditorParams: {
            ...referenceableColDef.cellEditorParams,
            openMenuOnFocus: params.isDesignSystem,
            ...customOptionParam,
        },
        resourceName: "employees",
        filterQueryParam: "employee_id",
    }
}

export const foremanColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...employeeColDefCreator({
            headerName: "Foreman",
            field: "/foreman",
            ...params,
        }),
        filterQueryParam: "foreman_id",
        extraSearchFilters: {
            exclude_user_role: "WORKER",
        },
        columnType: "foreman",
    }
}

export const costCodeColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["costCodes"] || {}
    return {
        ...referenceableColDefCreator({
            headerName: "Cost Code",
            field: "/cost_code",
            required: true,
            ...params,
        }),
        minWidth: 250,
        valueFormatter: valueFormatters.valueFormatter,
        cellRendererParams: {
            moreDetail: params.moreDetail,
            ...valueFormatters,
            titleFormatter: params.titleFormatter ?? valueFormatters.titleFormatter,
        },
        resourceName: "costCodes",
        filterQueryParam: "cost_code_id",
        useSelectV3: true,
        extraSearchFilters: {
            minimal_project_detail: "true",
        },
    }
}

export const companyAbsenceTypeColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["companyAbsenceTypes"] || {}

    return {
        ...referenceableColDefCreator({
            headerName: "Type",
            field: "/absence_type",
            required: true,
            ...params,
        }),
        minWidth: 250,
        valueFormatter: valueFormatters.valueFormatter,
        cellRendererParams: {
            moreDetail: params.moreDetail,
            ...valueFormatters,
        },
        resourceName: "companyAbsenceTypes",
        filterQueryParam: "absence_type_id",
    }
}

export const companyStartStopTypeColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...referenceableColDefCreator({
            headerName: "Type",
            field: "/company_start_stop_type",
            ...params,
        }),
        // This valueFormatter is used for the cell value (a start/stop time's own 'type' name)
        // as well as the picklist options (start/stop types). StartStopTimes are special because
        // we allow their 'type' name to de-sync from the parent type.
        valueFormatter: params => {
            const { data, value } = params
            if (isGroupRow(params)) {
                const shift = findShiftRowData(params.node as RowNode)
                if (!shift || !shift.data?.date) return ""
                const date = parse(shift.data.date, STORED_DATE_ONLY_FORMAT, new Date())
                const name = shift.data?.company_start_stop_type?.name ?? shift.data?.start_stop_type

                return `${format(date, "EEE, M/d")} ${name}`
            }
            if (data) {
                // If the cell value is just an id, then lookup the related data.
                if (isNumber(data.company_start_stop_type)) {
                    const id = data.company_start_stop_type
                    const startStopType = params.context.referenceableData["companyStartStopTypes"][id]
                    return startStopType?.name
                }
                // If the cell value has changed, its new `company_start_stop_type` will be a full object.
                // Use the start/stop type name from there, otherwise, pull it from the start/stop time itself.
                return data.company_start_stop_type?.name ?? data.start_stop_type
            }
            // Looking at a StartStopType option -> return its name
            return value?.name
        },
        resourceName: "companyStartStopTypes",
    }
}

export const equipmentColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["equipment"] || {}

    return {
        ...referenceableColDefCreator({
            headerName: "Equipment",
            field: "/equipment",
            required: true,
            ...params,
        }),
        valueFormatter: valueFormatters.valueFormatter,
        cellRendererParams: {
            moreDetail: params.moreDetail,
            ...valueFormatters,
        },
        resourceName: "equipment",
        filterQueryParam: "equipment_id",
        useSelectV3: true,
        extraSearchFilters: { is_active: "true" },
    }
}

export const materialColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["materials"] || {}

    return {
        ...referenceableColDefCreator({
            headerName: "Material",
            field: "/material",
            required: true,
            ...params,
        }),
        valueFormatter: valueFormatters.valueFormatter,
        cellRendererParams: {
            ...valueFormatters,
        },
        resourceName: "materials",
        filterQueryParam: "material_id",
        getQuickFilterText: filterFormatter,
        extraSearchFilters: { is_active: "true" },
    }
}

export const companyTradeColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["companyTrades"] || {}

    return {
        ...referenceableColDefCreator({
            headerName: "Trade",
            field: "/modifier_active/trade",
            required: false,
            editable: true,
            cellRenderer: ModifierCellRenderer,
            ...params,
        }),
        valueFormatter: valueFormatters.valueFormatter,
        cellRendererParams: {
            ...valueFormatters,
        },
        cellEditor: ModifierSelectorCellEditor,
        cellEditorPopup: true,
        resourceName: "companyTrades",
        filterQueryParam: "trade_id",
        useSelectV3: true,
    }
}

export const companyCrewTypeColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["companyCrewTypes"] || {}

    return {
        ...referenceableColDefCreator({
            headerName: "Labor Types",
            field: "/crew_types",
            required: false,
            editable: true,
            multiselect: true,
            ...params,
        }),
        cellRendererParams: {
            ...valueFormatters,
        },
        valueFormatter: p => multiChoiceValueFormatter(p.value),
        resourceName: "companyCrewTypes",
        useSelectV3: true,
    }
}

export const costCodeTypeColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["costCodeControls"] || {}

    return {
        ...referenceableColDefCreator({
            headerName: "Cost Code Type",
            field: "/cost_code_controls",
            required: false,
            editable: true,
            multiselect: true,
            ...params,
        }),
        cellRendererParams: {
            ...valueFormatters,
        },
        valueFormatter: p => multiChoiceValueFormatter(p.value),
        resourceName: "costCodeControls",
        useSelectV3: true,
    }
}

export const companyClassificationColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["companyClassifications"] || {}

    return {
        ...referenceableColDefCreator({
            headerName: "Classification",
            field: "/modifier_active/classification",
            required: false,
            editable: true,
            cellRenderer: ModifierCellRenderer,
            ...params,
        }),
        valueFormatter: valueFormatters.valueFormatter,
        cellRendererParams: {
            ...valueFormatters,
        },
        cellEditor: ModifierSelectorCellEditor,
        cellEditorPopup: true,
        resourceName: "companyClassifications",
        filterQueryParam: "classification_id",
        useSelectV3: true,
    }
}

export const picklistColDefCreator = (
    headerName: string,
    field: string,
    name: string,
    params: RmbxColDefParams
) => {
    const valueFormatters = referenceablesToValueFormatters["picklistItems"] || {}

    return {
        ...referenceableColDefCreator({
            headerName: headerName,
            field: field,
            required: false,
            editable: true,
            multiselect: true,
            alphabetize: true,
            hide: false,
            ...params,
        }),
        cellRendererParams: {
            ...valueFormatters,
        },
        extraSearchFilters: {
            picklist_name: name,
        },
        valueFormatter: (p: any) => multiChoiceValueFormatter(p.value),
        resourceName: "picklistItems",
        columnType: `picklist-${name}`,
        useSelectV3: true,
    }
}

export const customModifierColDefCreator = (
    name: string,
    slug: string,
    params: RmbxColDefParams = {}
): iRmbxColDef => {
    const valueFormatters = referenceablesToValueFormatters["picklistItems"] || {}
    const baseColDef = referenceableColDefCreator({
        headerName: name,
        field: `/modifier_active/${slug}`,
        required: false,
        cellRenderer: ModifierCellRenderer,
        ...params,
    })
    return {
        ...baseColDef,
        cellEditor: ModifierSelectorCellEditor,
        cellEditorParams: {
            ...baseColDef.cellEditorParams,
            isCreatable: true,
            isDesignSystem: true,
            openMenuOnFocus: true,
        },
        cellRendererParams: {
            ...valueFormatters,
        },
        cellEditorPopup: true,
        extraSearchFilters: {
            picklist: slug,
        },
        resourceName: "picklistItems",
        useSelectV3: true,
        columnType: `modifier-${slug}`,
        valueFormatter: valueFormatters.valueFormatter,
    }
}

export const analyticsDashboardColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["analyticsDashboards"] || {}

    return {
        ...referenceableColDefCreator({
            headerName: "Dashboard",
            field: "/analytics_dashboard",
            required: true,
            ...params,
        }),
        valueFormatter: valueFormatters.valueFormatter,
        cellRendererParams: {
            ...valueFormatters,
        },
        resourceName: "analyticsDashboards",
        filterQueryParam: "analytics_dashboard_id",
    }
}

export const projectMaterialColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["projectMaterials"] || {}

    return {
        ...referenceableColDefCreator({
            headerName: "Material",
            field: "/material",
            required: true,
            ...params,
        }),
        valueFormatter: valueFormatters.valueFormatter,
        cellRendererParams: {
            ...valueFormatters,
        },
        resourceName: "projectMaterials",
        filterQueryParam: "material_id",
    }
}

export const datetimeColDefCreator: iColDefCreator = (params = {}) => {
    let validators = params.cellValidators
    if (params.required) validators = [...(validators || []), isNotEmpty]
    return {
        ...standardColDefCreator(params),
        cellEditor: TimeCellEditor,
        cellEditorParams: {
            default: params.default || null,
            validators,
            minuteInterval: params.extraParams?.minuteInterval || 0,
            militaryTime: params.extraParams && !!params.extraParams.militaryTime,
            mode: "datetime",
            ...params.cellEditorParams,
        },
        width: params.width ? params.width : 190,
        comparator: compareDateStrings,
        suppressSizeToFit: true,
        cellEditorPopup: true,
        suppressKeyboardEvent: suppressKeyboardHandler,
        valueFormatter: params.valueFormatter || timeValueFormatter,
        customTooltip: params.customTooltip,
        columnType: "datetime",
    }
}

export const dateColDefCreator: iColDefCreator = (params = {}) => {
    const colDef = datetimeColDefCreator({
        headerName: "Date",
        field: "/date",
        width: params.width ? params.width : 140,
        ...params,
    })
    colDef.cellEditorParams.mode = "date"
    return {
        ...colDef,
        keyCreator: dateKeyCreator,
        cellRendererParams: {
            moreDetail: params.moreDetail,
            titleFormatter: dateValueFormatter,
        },
        // turns off search
        getQuickFilterText: () => "",
        filterQueryParam: ["startDate", "endDate"],
        columnType: "date",
    }
}

export const timeColDefCreator: iColDefCreator = (params = {}) => {
    const colDef = datetimeColDefCreator({
        width: params.width ? params.width : 100,
        ...params,
    })
    colDef.cellEditorParams.mode = "time"
    colDef.columnType = "time"
    return colDef
}

export const statusColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...standardColDefCreator({
            headerName: "Status",
            field: "/status",
            ...params,
        }),
        valueFormatter: titleCaseFormatter,
        width: params.width ? params.width : 130,
        suppressSizeToFit: true,
        editable: false,
    }
}

export const signaturePeriodColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...standardColDefCreator({
            headerName: "Signatures",
            field: "/options/signature_period",
            ...params,
        }),
        valueGetter: ({ data }) => {
            if (!data || !data.options || !data.options.require_signature) {
                return "off"
            }
            return data.options.signature_period
        },
        valueFormatter: titleCaseFormatter,
    }
}

export const currencyColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...standardColDefCreator(params),
        width: params.width ? params.width : 160,
        suppressSizeToFit: true,
        valueGetter: USDValueGetter,
        valueFormatter: USDValueFormatter,
        valueParser: USDValueParser,
        aggFunc: "sum",
        cellEditorParams: {
            ...(params.required ? { default: params.default ? params.default : 0 } : {}),
            validators: params.required ? [isNumber] : [isNumberOrNull],
        },
        columnType: "currency",
    }
}

export const currencySumColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...currencyColDefCreator(params),
        valueGetter: USDSumValueGetter,
        subfield: params.subfield ? params.subfield : params.field,
    }
}

export const buttonColDefCreator: iColDefCreator = (params = {}) => {
    return {
        width: params.width ? params.width : 50,
        suppressSizeToFit: true,
        resizable: false,
        sortable: false,
    }
}

export const checkboxColDefCreator: iColDefCreator = (params = {}) => {
    // this is a row selection checkbox,
    // if you need a generic checkbox for a boolean value use booleanCheckboxColDefCreator
    if (params.hide) return {}
    const pinned = params.pinned ? { pinned: params.pinned } : {}

    return {
        ...buttonColDefCreator(params),
        checkboxSelection: ({ data }) => !(params.hideNewRowCheckbox && data.newRow),
        colId: "row_selection_checkbox", // this helps us id the column for testing
        editable: false,
        headerCheckboxSelection:
            params.headerCheckboxSelection === undefined ? true : params.headerCheckboxSelection,
        headerCheckboxSelectionFilteredOnly: true,
        ...pinned,
        rowDrag: params.rowDrag,
    }
}

export const booleanCheckboxColDefCreator: iColDefCreator = (params = {}) => {
    // column definition for a boolean value in a table row, not a row selector checkbox
    return {
        ...buttonColDefCreator({ ...params, width: 45 }),
        headerName: params.headerName,
        editable: false, // this prevents the user from double-clicking into the cell
        cellRenderer: BooleanCheckboxCellRenderer,
        field: params.field,
    }
}

export const stringEmptyColDefCreator: iColDefCreator = (params = {}) => {
    const { width } = params
    return {
        ...standardColDefCreator(params),
        cellRenderer: CheckMarkCellRenderer,
        valueFormatter: (p: { value: string }) => (p.value !== "" ? "True" : "False"),
        suppressSizeToFit: true,
        sortable: false,
        width: width || 65,
        minWidth: width || 65,
    }
}

export const viewButtonColDefCreator: iColDefCreator = () => {
    // this is the view button that lives on field form list views - we are moving view to action bar
    // this can colDef creator can be removed after we migrate the history/view buttons out of list views WA-6636
    return { hide: true }
}

export const clickableTextColDefCreator: iColDefCreator = (params = {}) => {
    // clickable text cell for field form list views to launch forms in the right rail
    return {
        ...standardColDefCreator(params),
        cellRenderer: ClickableTextCellRenderer,
    }
}

export const modifyButtonColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...buttonColDefCreator({ width: 100, ...params }),
        headerName: params.headerName,
        cellRenderer: ModifyButtonCellRenderer(params.modifyFlow || "PROJECT_MODIFY"),
        field: params.field,
    }
}

export const workflowButtonColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...buttonColDefCreator({ width: 100, ...params }),
        headerName: params.headerName,
        cellRenderer: WorkflowButtonCellRenderer,
        field: params.field,
    }
}

export const viewCicoHistoryButtonColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...buttonColDefCreator({ width: 150, ...params }),
        headerName: params.headerName,
        cellRenderer: ViewCicoHistoryButtonCellRenderer,
        cellRendererParams: params.cellRendererParams,
        field: params.field,
    }
}

export const tokenRowButtonColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...standardColDefCreator({ width: 250, ...params }),
        headerName: params.headerName,
        cellRenderer: TokenRowButtonCellRenderer,
        field: params.field,
    }
}

export const slideToggleColDefCreator: iColDefCreator = (params = {}) => {
    const settings: Record<string, any> = {}
    if (params.cellEditor) settings.cellEditor = params.cellEditor
    if (params.cellEditorParams) settings.cellEditorParams = params.cellEditorParams
    return {
        ...standardColDefCreator(params),
        headerName: params.headerName,
        cellRenderer: SlideToggleCellRenderer,
        cellRendererParams: {
            onText: params?.onText ?? "On",
            offText: params?.offText ?? "Off",
            hideUneditable: !!params?.hideUneditable,
            isListValueType: params?.isListValueType ?? false,
            onValue: params?.onValue,
        },
        ...settings,
        field: params.field,
        maxWidth: params.maxWidth,
        valueSetter: params.valueSetter,
    }
}

export const viewHistoryColDefCreator: iColDefCreator = () => {
    // this is the history button that lives on field form list views - we are moving history to action bar
    // this can colDef creator can be removed after we migrate the history/view buttons out of list views WA-6636
    return { hide: true }
}

export const timekeepingStatusColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...referenceableColDefCreator({
            headerName: "Status",
            field: "/status",
            required: false,
            ...params,
        }),
        keyCreator: params => {
            if (typeof params.value === "object") {
                return params.value.name
            }
            return params.value ?? "undefined"
        },
        keyToValue: (params: Record<string, any>) => {
            if (params.key === -1) return "No Status"

            return Object.values(params.referenceableData[params.colDef.resourceName] as iTimekeepingStatus[]).find(
                (status: iTimekeepingStatus) => status.name === params.key
            )
        },
        valueGetter: params => {
            // @ts-ignore
            const id = jsonPointerValueGetter(params)
            if (typeof id === "string") {
                if (id === "__NONE__") return { name: "__NONE__", label: "No Status" }
                return Object.values(params.context.referenceableData.timekeepingStatuses).find(
                    // @ts-ignore
                    (ts: iTimekeepingStatus) => ts.name === id
                )
            }
            return id
        },
        valueFormatter: customStatusValueFormatter,

        cellRendererParams: {
            valueFormatter: customStatusValueFormatter,
            titleFormatter: customStatusValueFormatter,
        },
        overrideValueOnGroupPaste: "PENDING",
        resourceName: "timekeepingStatuses",
        extraSearchFilters: {
            filter_by_role: "true",
            order_by: "sort_order",
        },
        fakeKey: "-1",
        fakeValue: "__NONE__",
    }
}

export const hoursFromMinutesColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...numberColDefCreator({ width: 70, required: true, ...params }),
        valueGetter: hoursFromMinutesValueGetter,
        valueParser: hoursToMinutesValueParser,
    }
}

export const apiIntegrationTokenColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...standardColDefCreator(params),
        headerName: params.headerName,
        field: params.field,
        valueGetter: apiIntegrationTokenValueGetter,
        wrapText: true,
    }
}

/** Production Tracking Week Columns */
export const productionWeekColDefCreator: iColDefCreator = (params = {}) => {
    const index = params.valueIndex || 0

    // Columns only for exporting to CSV/Excel, displaying production hours.
    if (params.hide) {
        return {
            ...standardColDefCreator(params),
            headerValueGetter: (p: Record<string, any>) => getProductionWeekHeader(p, -index, true),
            valueGetter: p => p.data.week_hours[index],
        }
    }

    return {
        ...standardColDefCreator(params),
        minWidth: params.width || 150,
        cellRenderer: MultiLineCellRenderer,
        // Value to be displayed when row grouping is applied.
        aggFunc: "ptWeekAgFunc",
        cellStyle: { cursor: "pointer" },
        comparator: (valueA, valueB) => defaultComparator(valueA.hours, valueB.hours), // For table sorting.
        exportFormatter: (value: tProductionWeekValue) => value.quantities, // Format cell value before exporting.
        getQuickFilterText: p => p.value.quantities, // For search in AG Grid table.
        headerExportFormatter: (value: string) => `${value} Production`, // Format header value before exporting.
        /**
         * If "headerName" is not passed in, subtract the number of weeks
         * from the filter startDate to get the displayed date.
         */
        headerValueGetter: (p: Record<string, any>) => getProductionWeekHeader(p, -index),
        openSideRail: true,
        valueIndex: params.valueIndex,
        valueGetter: p => ptHoursQuantitiesValueGetter(p, index),
        columnType: "productionWeek",
    }
}

/**
 * Render multi-line cell content, currently only support 2 lines - title and subtitle.
 * valueGetter is required.
 */
export const multiLineColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...standardColDefCreator(params),
        cellRenderer: MultiLineCellRenderer,
        valueGetter: params.valueGetter,
        comparator: (valueA, valueB) => defaultComparator(valueA.content.title, valueB.content.title),
        columnType: "string",
    }
}

// Temporary col def types for WA-3936 ----------------------------------------

export const verifiedColDefCreator: iColDefCreator = (params = {}) => ({
    headerName: params.headerName,
    field: params.field,
    cellRenderer: CheckMarkCellRenderer,
    valueFormatter: booleanValueFormatter,
    width: 135,
    // TODO: this is a hack to make grouping work, find a better way
    keyCreator: (p: { value?: boolean }) => {
        return !isEmpty(p.value) ? "verified" : ""
    },
    suppressSizeToFit: true,
})

export const colorPickerColDefCreator: iColDefCreator = (params = {}) => ({
    headerName: params.headerName,
    field: params.field,
    cellRenderer: ColorPickerCellRenderer,
    maxWidth: 100,
    editable: params => !params.data.is_hidden,
    cellEditor: ColorCellEditor,
    cellEditorPopup: true,
    valueGetter: params => {
        return { selected_color: params.data.selected_color, is_outline: params.data.is_outline }
    },
    valueSetter: params => {
        if (
            params.data.selected_color !== params.newValue.selected_color ||
            params.data.is_outline !== params.newValue.is_outline
        ) {
            params.data.is_outline = params.newValue.is_outline
            params.data.selected_color = params.newValue.selected_color
            params.data.traffic_color = params.newValue.selected_color
            params.data.is_traffic_outline = params.newValue.is_outline
            return true
        }
        return false
    },
    columnType: "color",
})

export const laborHoursColDefCreator: iColDefCreator = (params = {}) => ({
    headerName: params.headerName,
    aggFunc: "sum",
    valueGetter: ({ data }) => {
        if (!data || !data["labor_hours"]) {
            return 0
        }
        return data["labor_hours"]
    },
    width: 150,
    suppressSizeToFit: true,
    columnType: "number",
})

export const totalCostColDefCreator: iColDefCreator = (params = {}) =>
    currencyColDefCreator({
        headerName: params.headerName,
        field: "/total_cost",
        width: 140,
    })

// colDef to use for new coversheet which include totals for Lower Tier Sub and Other Items
export const totalCostWithLowerAndOtherColDefCreator: iColDefCreator = (params = {}) =>
    currencyColDefCreator({
        headerName: params.headerName,
        field: "/total_cost_lower_and_others",
        width: 140,
    })

export const totalCostSumColDefCreator: iColDefCreator = (params = {}) =>
    currencySumColDefCreator({
        headerName: params.headerName,
        field: params.field,
        subfield: "total_cost",
        width: 140,
    })

export const equipmentCostColDefCreator: iColDefCreator = (params = {}) =>
    currencyColDefCreator({
        headerName: params.headerName,
        field: "/equipment_cost",
        width: 140,
    })

export const laborCostColDefCreator: iColDefCreator = (params = {}) =>
    currencyColDefCreator({
        headerName: params.headerName,
        field: "/labor_cost",
        width: 140,
    })

export const materialCostColDefCreator: iColDefCreator = (params = {}) =>
    currencyColDefCreator({
        headerName: params.headerName,
        field: "/material_cost",
        width: 140,
    })

export const nonstandardMaterialCostColDefCreator: iColDefCreator = (params = {}) =>
    currencyColDefCreator({
        headerName: params.headerName,
        field: "/nonstandard_material_cost",
        width: 140,
    })

export const lowerTierSubCostColDefCreator: iColDefCreator = (params = {}) =>
    currencyColDefCreator({
        headerName: params.headerName,
        field: "/lower_tier_sub_cost",
        width: 140,
    })

export const otherItemsCostColDefCreator: iColDefCreator = (params = {}) =>
    currencyColDefCreator({
        headerName: params.headerName,
        field: "/other_items_cost",
        width: 140,
    })

export const wrenchTimeColDefCreator: iColDefCreator = (params = {}) => ({
    headerName: params.headerName,
    field: "/wrench_time",
    valueGetter: durationSecondsValueGetter,
    width: 140,
    suppressSizeToFit: true,
    columnType: "number",
})

// allows for smooth transition from a list view using string for GC Ref # to a PCO Picker (cost-item-selector)
export const pcoColDefCreator: iColDefCreator = (params = {}) => ({
    headerName: params.headerName,
    valueGetter: ({ data }) => {
        if (!params?.field) return null
        const value = data?.[params.field]
        if (!value || typeof value === "string") {
            return value
        }
        return value?.["change_order_number"]
    },
    width: 150,
    suppressSizeToFit: true,
    columnType: "pco",
})

export const rowCountColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...standardColDefCreator(params),
        valueGetter: rowCountValueGetter,
        minWidth: params.width ? params.width : 50,
        width: params.width ? params.width : 50,
    }
}

export const costCodeControlColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...standardColDefCreator(params),
        rowGroup: params.rowGroup,
        minWidth: params.width ? params.width : 100,
        width: params.width ? params.width : 300,
        cellRendererParams: {
            suppressCount: true,
        },
    }
}

// Create a column to handle the display of attachments
export const attachmentsColDefCreator: iColDefCreator = (params = {}) => {
    const standardDefs = standardColDefCreator(params)
    return {
        ...standardDefs,
        rowGroup: params.rowGroup,
        minWidth: params.width ? params.width : 270,
        valueGetter: referenceableValueGetter,
        cellEditorParams: {
            validators: params.required ? [isNotEmpty] : [],
        },
        cellRendererParams: {
            suppressCount: true,
        },
        cellRenderer: AttachmentsRenderer(),
        // This setting translates the original editable setting passed in
        // to control the upload button in the renderer
        ...(getFlagEnabled("WA-7920-attachment-upload-button") ? { uploadEnabled: standardDefs.editable } : {}),
        // This setting ensures you can't edit the cell in the main AG Grid table,
        // which makes no sense to be able to edit
        ...(getFlagEnabled("WA-7857-se-attachment-icons") ? { editable: false } : {}),
        ...(getFlagEnabled("WA-7964-shift-extra-attachment-required") ? { editable: true, cellEditor: null } : {}),
    }
}

export const timeCardColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["workShifts"] || {}

    return {
        ...referenceableColDefCreator({
            headerName: "Work Shifts",
            field: "/work_shift_id",
            editable: false,
            cellRenderer: TimeCardTitleCellRenderer,
            ...params,
        }),
        cellRendererParams: {
            ...valueFormatters,
        },
        valueFormatter: valueFormatters.valueFormatter,
        resourceName: "workShifts",
    }
}

// Allows the selection of a timecard for adjusting a timecard entry's ownership
export const editableTimeCardColDefCreator: iColDefCreator = (params = {}) => {
    const valueFormatters = referenceablesToValueFormatters["timeCards"] || {}

    return {
        ...referenceableColDefCreator({
            field: "/work_shift_id",
            cellRenderer: TkTimeCardTitleCellRenderer,
            ...params,
        }),
        cellRendererParams: {
            ...valueFormatters,
        },
        valueFormatter: valueFormatters.valueFormatter,
        resourceName: "workShifts",
        filterQueryParam: "start_date",
        minWidth: params.width || 300,
        keyCreator: idKeyCreator,
        keyToValue: referenceableKeyToValue,
        valueGetter: referenceableValueGetter,
        comparator: getFormattedValueComparatorForField(params.field),
        cellEditor: ReferenceableSelectorCellEditor,
        cellEditorPopup: true,
        cellEditorParams: {
            idsToExclude: params?.idsToExclude?.length ? params.idsToExclude : null,
            default: params?.default ?? null,
            validators: [
                ...(params.required ? [isNotEmpty] : []),
                ...(params.unique ? [validateUniqueEntry] : []),
                ...(params.cellValidators ?? []),
            ],
            isDesignSystem: true,
            openMenuOnFocus: true,
        },
        suppressKeyboardEvent: suppressKeyboardHandler,
        multiselect: params.multiselect,
        useSelectV3: true,
    }
}

// Allows the selection of multiple related entities (e.g. classification modifiers) that can be associated
// with a base entity (e.g. trade modifiers). Example: Assign Trades to a Classification on the Picklists page.
export const entityRelationshipsColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...referenceableColDefCreator({
            editable: params.editable,
            headerName: params.headerName,
            field: params.field,
            required: false,
            multiselect: true,
        }),
        comparator: (valueA, valueB) =>
            defaultComparator(multiChoiceValueFormatter(valueA), multiChoiceValueFormatter(valueB)),
        valueFormatter: p => multiChoiceValueFormatter(p.value),
        resourceName: params.resourceName,
        useSelectV3: true,
    }
}

export const signatureActionsColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...standardColDefCreator({ width: 250, ...params }),
        headerName: params.headerName,
        cellRenderer: SignatureActionsCellRenderer,
        field: params.field,
        editable: false,
        sortable: false,
    }
}

export const fieldFormTemplateColDefCreator: iColDefCreator = (params = {}) => {
    const { resultType, field = "missing_field" } = params
    // if a resultType is given, try to find it in the column types - or use default, which is standardColDefCreator
    const resultColDef = standardColCreators?.[resultType ?? "default"] ?? standardColDefCreator
    return {
        ...resultColDef({ width: 250, ...params }),
        headerName: params.headerName,
        cellRenderer: FFTemplateCellRenderer(params),
        field: field,
        type: resultType,
        editable: false,
        sortable: false,
    }
}

export const sumCurrencyRowFieldColDefCreator: iColDefCreator = (params = {}) => {
    return {
        ...currencyColDefCreator(params),
        cellRenderer: SumFieldsCellRenderer(params),
    }
}

/**
 * a dynamic colDef that can take in the name of a helper from rmbx_templates to format the desired calculations
 * field is the destination of the result - it should be different than your inputField
 * inputField is where the source data is - so if the helper processes a list - inputField is a path to the list
 * helper is a string name of a helper in the rmbx_templates helper library
 * resultType what column type is the result - we build it on the fly string, boolean, currency, etc
 *  {
 *    "field": "/total_cost_other_items",
 *    "inputField": "/store/subcontractor_use/other_items",
 *    "headerName": "Helper Other Items Cost",
 *    "helper": "getOtherItemsPricingTotal",
 *    "resultType": "currency",
 *    "type": "ff-helper"
 *  },
 * @returns
 */
export const fieldFormHelperColDefCreator: iColDefCreator = (params = {}) => {
    const { resultType, inputField, helper, field, extraArgs = [] } = params
    // if a resultType is given, try to find it in the column types - or use default, which is standardColDefCreator
    const resultColDef = standardColCreators?.[resultType ?? "default"] ?? standardColDefCreator
    // if the resultColDef has a valueGetter, we should call that after getting our result
    // so if our helper returns a currency value, we use `field` to determine the path for the result
    // the resultColDef valueGetter would then look at the `field` value and format as $$
    const resultValueGetter = resultColDef(params)?.valueGetter
    return {
        ...resultColDef({ width: 250, ...params }),
        headerName: params.headerName,
        valueGetter: data => {
            if (helper && !FieldFormHelpers?.[helper]) return "Missing Helper"
            if (getFlagEnabled("WA-8301-fix-ff-helper-colDef")) {
                if (helper && inputField && field && data.data?.[inputField] && FieldFormHelpers?.[helper]) {
                    let value = null
                    // the value can be a string or a json string of something like a list or object
                    // try to parse it, and if not use it as the raw value
                    try {
                        value = JSON.parse(data.data[inputField])
                    } catch (error) {
                        value = data.data[inputField]
                    }
                    data.data[field] = FieldFormHelpers?.[helper](value, ...extraArgs)
                    if (resultValueGetter && typeof resultValueGetter == "function") return resultValueGetter(data)
                    return data
                }
            } else {
                if (helper && inputField && field && data.data?.[inputField] && FieldFormHelpers?.[helper]) {
                    data.data[field] = FieldFormHelpers?.[helper](data.data[inputField])
                    if (resultValueGetter && typeof resultValueGetter == "function") return resultValueGetter(data)
                    return data
                }
            }
            return resultType && ["number", "integer", "currency"].includes(resultType) ? 0 : ""
        },
        field: field,
        type: resultType,
        editable: false,
        sortable: false,
    }
}

const standardColCreators: tStandardColDefCreators = {
    default: standardColDefCreator,
    string: stringColDefCreator,
    textarea: stringColDefCreator,
    number: numberColDefCreator,
    integer: integerColDefCreator,
    boolean: booleanColDefCreator,
    "employee-selector": employeeColDefCreator,
    "costcode-selector": costCodeColDefCreator,
    "equipment-selector": equipmentColDefCreator,
    "material-selector": materialColDefCreator,
    date: dateColDefCreator,
    "time-picker": timeColDefCreator,
    "datetime-picker": datetimeColDefCreator,
    status: statusColDefCreator,
    "signature-period": signaturePeriodColDefCreator,
    currency: currencyColDefCreator,
    checkbox: checkboxColDefCreator,
    "row-count": rowCountColDefCreator,
    "view-button": viewButtonColDefCreator,
    "view-history": viewHistoryColDefCreator,
    "timekeeping-status": timekeepingStatusColDefCreator,
    "masked-length": integerColDefCreator,
    "labor-hours": laborHoursColDefCreator,
    "lower-tier-sub-cost": lowerTierSubCostColDefCreator,
    "other-items-cost": otherItemsCostColDefCreator,
    "total-cost": totalCostColDefCreator,
    "total-cost-sum": totalCostSumColDefCreator,
    "total-cost-lower-and-other": totalCostWithLowerAndOtherColDefCreator,
    "equipment-cost": equipmentCostColDefCreator,
    "labor-cost": laborCostColDefCreator,
    "material-cost": materialCostColDefCreator,
    "nonstandard-material-cost": nonstandardMaterialCostColDefCreator,
    "wrench-time": wrenchTimeColDefCreator,
    "modify-button": modifyButtonColDefCreator,
    "image-selector": attachmentsColDefCreator,
    "boolean-checkbox": booleanCheckboxColDefCreator,
    "clickable-text": clickableTextColDefCreator,
    "length-limited-string": lengthLimitedColDefCreator,
    "pco-number": pcoColDefCreator,
    "ff-helper": fieldFormHelperColDefCreator,
    "ff-template": fieldFormTemplateColDefCreator,
    "sum-fields": sumCurrencyRowFieldColDefCreator,
    // TODO: The type from the schema may need to be changed to
    // "attachment-selector" - or maybe we accept both types but they
    // both go to the same coldefcreator

    // Temporary col def types for WA-3936
    verified: verifiedColDefCreator,

    //Temporary col def type for WA-4435
    "read-only": stringColDefCreator,
}

export const colDefCreator: iColDefCreator = params => {
    const type = params.type as keyof tStandardColDefCreators
    if (type && standardColCreators[type]) return standardColCreators[type](params)

    return {
        ...standardColDefCreator({ headerName: params.headerName }),
        valueGetter: () => "SCHEMA ERROR",
    }
}
